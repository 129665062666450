// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projekt-dokazivanje-koncepta-kamere-jsx": () => import("./../../../src/pages/projekt-dokazivanje-koncepta-kamere.jsx" /* webpackChunkName: "component---src-pages-projekt-dokazivanje-koncepta-kamere-jsx" */),
  "component---src-pages-projekt-integrator-tvrtke-intis-engineering-jsx": () => import("./../../../src/pages/projekt-integrator-tvrtke-intis-engineering.jsx" /* webpackChunkName: "component---src-pages-projekt-integrator-tvrtke-intis-engineering-jsx" */),
  "component---src-pages-schedule-jsx": () => import("./../../../src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */),
  "component---src-pages-signin-jsx": () => import("./../../../src/pages/signin.jsx" /* webpackChunkName: "component---src-pages-signin-jsx" */)
}

